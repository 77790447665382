import React from 'react'
import Layout from './Layout'
import { Link } from 'react-router-dom';

import BlogCard1 from './Blog Cards/BlogCard1';
import BlogCard2 from './Blog Cards/BlogCard2';
import BlogCard3 from './Blog Cards/BlogCard3';
import BlogCard4 from './Blog Cards/BlogCard4';
import BlogCard5 from './Blog Cards/BlogCard5';
import BlogCard6 from './Blog Cards/BlogCard6';


const Blogs = () => {
  return (
    <Layout>
    <div>
    
    <div class="container mx-auto grid md:grid-cols-3 gap-8 py-8 px-8">
    
        <BlogCard1/>

        <BlogCard2/>

        <BlogCard3/>
        
        <BlogCard4/>

        <BlogCard5/>

        <BlogCard6/>

    </div>




  </div>
    </Layout>
  )
}

export default Blogs