import React, { useState } from 'react'





const Emailer = () => {

    const [email, setEmail] = useState(null);

    const handleButtonClick = () => {
    const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfFt3H1VhzrvYR11_boLx4iGpHYNnrTcpBoVMaGAVtmpYYRhQ/viewform?usp=pp_url&entry.119590698=';
    const fullURL = `${googleFormURL}${encodeURIComponent(email)}`;
    window.open(fullURL, '_blank');
  };

  // Function to check if the email input is valid
  const isEmailValid = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Disable the button if the email is invalid or empty
  const isButtonDisabled = !email || !isEmailValid(email);


  return (
    <div className='w-full py-16 text-white px-6' id='emailer'>
        <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
            <div className='lg:col-span-2'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Sign up and become the first to experience!</h1>
                <p>Join our exclusive beta group! Be among the first few to experience Worksheep</p>
            </div>
            <div className='my-4'>
                <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                    <input 
                    className='p-3 flex w-full rounded-md text-black' 
                    type="email" 
                    placeholder='Enter Email'
                    onChange={(e) => setEmail(e.target.value)}/>
                    <button 
                    className={`bg-black w-[200px] rounded-md font-medium ml-4 my-6 px-6 py-3 text-white ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleButtonClick}
                    disabled={isButtonDisabled} 
                    >Notify Me</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Emailer